import React, { useEffect, useState } from "react";
import "./navba.css";
import logo from "../../../Asserts/Images/logoa.png";
import { Icon } from "@iconify/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { useContext } from "react";
import axios from "axios";
import navnoti from "../../../Asserts/Images/navnoti.svg";
import { useSessionErrorHandler } from "../Managesession";
import UsersSidebar from "./Active Users/UsersSidebar";
import { socket } from "../Bloodimageanalysis/globalsocket";
import dummyProfile from "./../../../Asserts/Images/DummyProfile.png";
import BranchLoading from "../branchLoading/BranchLoading";

export default function Navbar({ isContentVisible, searchDropdown }) {
  const [notifications, setNotifications] = useState([]);
  const [navActive,setNavActive] = useState('home')
  const loc = useLocation()
  useEffect(()=>{
    if(loc.pathname==='/bookameeting'){
      setNavActive('service')
      // console.log(loc.pathname)
    }
    else if(loc.pathname==='/readanalysis'){
      setNavActive('service')
      // console.log(loc.pathname)
    }
    else if (loc.pathname==='callus'){
      setNavActive('contact')
    }
    else if (loc.pathname==='/'){
      setNavActive('home')
    }
    else{
      setNavActive('')
    }
  })
  const handleSessionError = useSessionErrorHandler();
  const [newNotificationsCount, setNewNotificationsCount] = useState(null);
  const {
    setActiveButtonmet,
    showsidebar,
    setShowsidebar,
    setUser,
    notification,
    setNotification,
    user,
    setToken,
    setLoading,
    loading,
    token,
    islogin,
    setActiveButton,
    setActiveTab,
    setTogglelogin,
    userSideBar,
    setUserSideBar,
  } = useContext(UserContext);

  function ShowUserSideBar() {
    if (userSideBar) {
      setUserSideBar(false);
    } else {
      setUserSideBar(true);
    }
  }

  const axiosInstance = axios.create({
    withCredentials: true,
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      const handleNotification = (data) => {
        // console.log("Notification", data);
        if (user?.role === "admin" && data.Deliver_To === "admin") {
          setNotifications((prevNotifications) => {
            const newNotifications = [...prevNotifications, data];
            newNotifications.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
            return newNotifications;
          });
          setNewNotificationsCount((prevCount) => prevCount + 1);
        }
        if (data.userID === user._id && data.Deliver_To === "user") {
          setNotifications((prevNotifications) => {
            const newNotifications = [...prevNotifications, data];
            newNotifications.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
            return newNotifications;
          });
          setNewNotificationsCount((prevCount) => prevCount + 1);
        }
      };

      socket.on("notification", handleNotification);

      return () => {
        socket.off("notification", handleNotification);
      };
    }
  }, []);

  const fetchNotificationsFromDB = async () => {
    try {
      if (user) {
        // Check if user is not null or undefined
        if (user?.role === "user") {
          await axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}/notification/getuserNotificaitons?userID=${user._id}`,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              // console.log("user", response);

              setNewNotificationsCount(0);
              setNotifications([...response.data.response]);
              response?.data?.response.forEach((notification) => {
                if (!notification.Read) {
                  setNewNotificationsCount((prevCount) => prevCount + 1);
                }
              });
            })
            .catch((Err) => {
              handleSessionError(Err);
              // console.log(Err);
            });
        }
        if (user?.role === "admin") {
          await axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}/notification/getNotificationsForAdmin?role=${user?.role}&Deliver_To=admin`,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              // console.log("notiication", response);
              setNotifications([...response.data.response]);
              response.data.response.forEach((notification) => {
                if (notification.Read !== true) {
                  setNewNotificationsCount((prevCount) => prevCount + 1);
                }
              });
            })
            .catch((err) => {
              // console.log(err);
              handleSessionError(err);
            });
        }
      }
    } catch (err) {
      handleSessionError(err);
      // console.log(err);
    }
  };

  useEffect(() => {
    fetchNotificationsFromDB();
  }, []);

  // const [isContentVisible, setIsContentVisible] = useState(false);
  // const [showsidebar, setShowsidebar] = useState(false);
  const [isContentVisible2, setIsContentVisible2] = useState(false);
  // const {  , setShowsidebar} = useContext(UserContext);
  // const [notification, setNotification] = useState(false);

  const [khadmat, setKhadmat] = useState(false);
  // const [userType, setUserType] = useState('user');
  // const searchdropdown = () => {
  // 	setIsContentVisible(!isContentVisible);
  // };
  const testfun = () => {
    setActiveButtonmet("قراءة تحليل");
  };

  const bookmeetingfun = () => {
    setActiveButtonmet("لقاء مع الدكتور أونلاين");
  };

  const shownotific = async () => {
    try {
      setNotification(!notification);
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/notification/markAllAsRead/?userID=${user._id}&role=${user.role}`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log("updated", res);
          setNotification(!notification);
          setNewNotificationsCount(0);
        })
        .catch((err) => {
          handleSessionError(err);
          // console.log(err);
        });
    } catch (err) {
      // console.log(err);
    }
  };

  const tooglesidebar = () => {
    setShowsidebar(!showsidebar);
  };

  const handleToggleContent = (e) => {
    e.stopPropagation(); // Prevent event from reaching the body
    setIsContentVisible2(!isContentVisible2);
    setShowsidebar(true);
    // alert(showsidebar);
  };

  const handleTogglekhadmat = (e) => {
    e.stopPropagation();
    setKhadmat(!khadmat);
    setShowsidebar(true);
  };

  const addameeting = () => {
    navigate("/readanalysis");
    setActiveButtonmet("قراءة تحليل");
  };

  const gotohomescreen = () => {
    navigate("/");
    setActiveButton("الفيديوهات");
  };

  const gotologinpage = async () => {
    if (user) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      setToken(null);
      setUser(null);
      navigate("/");
      window.location.reload();
    }
  };
  const HandleNotificationController = async (e, data) => {
    setLoading(true);
    // console.log("notification", data);
    e.stopPropagation();
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/notification/updateSeenStatus/${data._id}`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("updated", res);
        navigate(data.Link);
        setNotification(!notification);
        setNewNotificationsCount((prevCount) => prevCount - 1);
        fetchNotificationsFromDB();
      })
      .catch((err) => {
        handleSessionError(err);
        // console.log(err);
      });
    setLoading(false);
  };
  const gotheprofilepage = () => {
    navigate("/userprofile");
  };

  const gotonotification = () => {
    setActiveTab("لقاءات");
    navigate("/myanalyticsadmain");
  };

  const stppropagation = (e) => {
    // e.stopPropagation();
  };

  const gotomeeting = () => {
    setActiveTab("تحليلات");
  };

  const gototests = () => {
    setActiveTab("لقاءات");
  };

  const gotologin = () => {
    navigate("/login");
    setTogglelogin("login");
  };

  const gotocreateaccount = () => {
    navigate("/login");
    setTogglelogin("register");
  };

  return (
    <nav className="mynav">
      <div className={notification ? "changeingdivmain2" : "changeingdivmain"}>
        <div className="popupmaindiv">
          {loading ? (
            <BranchLoading />
          ) : (
            <>
              {notifications && notifications.length <= 0 ? (
                <>
                  <div className="nonotificationyet">لا يوجد إشعار حتى الآن</div>
                </>
              ) : (
                notifications.map((data) => {
                  return (
                    <div
                      className="mainnotifyshowdiv"
                      style={{
                        background: data.Seen ? "transparent" : "#F9EAF7",
                      }}
                      onClick={(e) => HandleNotificationController(e, data)}
                    >
                      <div>{data.Message}</div>
                      <div className="dateandtimenotify">
                        <div>{data?.time}</div>
                        <div>{data?.date}</div>
                      </div>
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
      </div>

      {/* this is the side bar in the nav */}
      <div
        className={showsidebar ? "sidebarmainusad" : "sidebarmainusad2"}
        onClick={(e) => stppropagation(e)}
      >
        <div className="sidebarmaindivnew">
          <div className="sidebarcontainer">
            <div className="superadmainside">
              {/* <div className="crossiconforshut">
							<Icon onClick={tooglesidebar} icon="maki:cross" width="1.2em" height="1.2em" />
						</div> */}

              <div className="user-profile-pic">
                <img src={user?.photo} alt="" />
              </div>

              <div>
                {user?.first_name}
                {""}
              </div>
            </div>

            <div className="superadmainroutes">
              {user?.role === "user" ? (
                <NavLink to="/">
                  <div>
                    {" "}
                    الصفحة الرئيسية{" "}
                    <Icon icon="ic:round-home" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : user?.role === "admin" ? (
                <NavLink to="/">
                  <div>
                    الصفحة الرئيسية{" "}
                    <Icon icon="ic:round-home" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : user?.role === "super-admin" ? (
                <NavLink to="/">
                  <div>
                    الصفحة الرئيسية{" "}
                    <Icon icon="ic:round-home" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : (
                <></>
              )}
              {
                user && (
                  <NavLink to="/events">
                    <div>
                      {" "}
                      الفاعليات{" "}
                      <Icon
                        icon="mdi:events"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                )
              }
               {
                user && user.role==='user' && (
                  <NavLink to="/bookedevents">
                    <div>
                      {" "}
                      الفاعليات المحجوزة{" "}
                      <Icon
                        icon="mdi:events"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                )
              }
              {
                user?.role === "super-admin" && (
                  <NavLink to="/allmanagers">
                    <div>
                      {" "}
                      المديرون{" "}
                      <Icon
                        icon="mingcute:user-star-fill"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                )
              }
              {
                user?.role === "super-admin" && (
                  <NavLink to="/addingmanager">
                    <div>
                      {" "}
                      إضافة مدير{" "}
                      <Icon
                        icon="fa-solid:user-plus"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                )
              }
              {
                user?.role === "super-admin" && (
                  <NavLink to="/latestmods">
                    <div>
                      {" "}
                      آخر التعديلات{" "}
                      <Icon
                        icon="ph:pencil-simple-line-light"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                )
              }
              {
                  ((user?.role === 'super-admin') || (user?.role === 'admin')) ?
                  <NavLink to="/allusers">
                  <div>
                  المستخدمين{" "}
                    <Icon
                      icon="fluent:people-team-32-filled"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink> : <></>
                }
              {/* yahaan */}
              {user?.role === "user" ? (
                <NavLink to="/myanalytics">
                  <div>
                    تحليلاتي{" "}
                    <Icon icon="ep:list" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : (user?.role === "admin"||user?.role === "super-admin") ? (
                <NavLink to="/myanalyticsadmain">
                  <div onClick={gototests}>
                    تحليلاتي{" "}
                    <Icon icon="ep:list" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              )  : (
                <></>
              )}
              {user?.role === "user" ? (
                <>
                  <NavLink to="/mymeeting">
                    <div>
                      اللقاءات{" "}
                      <Icon icon="mdi:users" width="1.2em" height="1.2em" />
                    </div>
                  </NavLink>
                </>
              ) : (user?.role === "admin"||user?.role === "super-admin") ? (
                <NavLink to="/meetingnotify">
                  <div onClick={gotomeeting}>
                    اللقاءات{" "}
                    <Icon icon="mdi:users" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              )  : (
                <></>
              )}
              {user?.role === "user" ? (
                <>
                  <NavLink to="/buyedbooks">
                    <div>
                      {" "}
                      كتبي{" "}
                      <Icon
                        icon="material-symbols:library-books"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                </>
              ) : user !== null && user?.role === "admin"  ? (
                <NavLink to="/catageries">
                  <div>
                    الأقسام{" "}
                    <Icon
                      icon="ic:round-dashboard"
                      width="1.2em"
                      height="1.2em"
                    />{" "}
                  </div>
                </NavLink>
              ) : user !== null && user?.role === "super-admin"  ? (
                <NavLink to="/catageries">
                  <div>
                    الأقسام{" "}
                    <Icon
                      icon="ic:round-dashboard"
                      width="1.2em"
                      height="1.2em"
                    />{" "}
                  </div>
                </NavLink>
              ) : (
                <></>
              )}
              {user && user?.role === "user" ? (
                <></>
              ) : user?.role === "admin" ? (
                <NavLink to="/uploadbanner">
                  <div>
                    الإعلانات{" "}
                    <Icon icon="solar:book-bold" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : user?.role === "super-admin" ? (
                <NavLink to="/uploadbanner">
                  <div>
                    الإعلانات{" "}
                    <Icon icon="solar:book-bold" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : (
                <></>
              )}
              {user && user?.role === "admin" && (
                <NavLink to="/unpaidlabworks">
                  <div>
                    تحليل غير مدفوع الأجر{" "}
                    <Icon
                      icon="fluent:document-bullet-list-off-20-filled"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink>
              )}{" "}
              {user && (user?.role === "admin"||user?.role === "super-admin") && (
                <NavLink to="/transcitionStatus">
                  <div>
                    التحقق من حالة الدفع{" "}
                    <Icon
                      icon="mdi:account-payment-outline"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink>
              )}
              {user && user?.role === "user" ? (
                <></>
              ) : (user?.role === "admin") ? (
                <NavLink to="/communation">
                  <div>
                    اعدادات التواصل{" "}
                    <Icon
                      icon="ic:baseline-whatsapp"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink>
              ) : (
                <></>
              )}
              {
                (user?.role === "super-admin") && (
                  <NavLink to="/communation">
                    <div>
                      اعدادات التواصل{" "}
                      <Icon
                        icon="ic:baseline-whatsapp"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </NavLink>
                )
              }
              {
                 (user?.role==='super-admin'||user?.role==='admin')&& <NavLink to="/createEvents">
                 <div>
                 الأحداث{" "}
                   <Icon
                    icon="mdi:virtual-meeting"
                     width="1.2em"
                     height="1.2em"
                   />
                 </div>
               </NavLink>
              }
              {user && user?.role === "user" ? (
                <NavLink to="/paymenthistory">
                  <div>
                    مدفوعاتي{" "}
                    <Icon
                      icon="solar:dollar-bold"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink>
              ) : user?.role === "admin" ? (
                <NavLink to="/paymenthistory">
                  <div>
                    مدفوعاتي{" "}
                    <Icon
                      icon="solar:dollar-bold"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink>
              ) : user?.role === "super-admin" ? (
                <NavLink to="/paymenthistory">
                  <div>
                    مدفوعاتي{" "}
                    <Icon
                      icon="solar:dollar-bold"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </NavLink>
              ) : (
                <></>
              )}

            </div>


            <div className="settingsandlogout">
              {/* this is the first dropdown in the website  */}

              {user?.role === "user" && (
                <>
                  <div onClick={(e) => handleTogglekhadmat(e)}>
                    <div className="dropdowniconf">
                      {khadmat ? (
                        <Icon
                          // onClick={handleTogglekhadmat}
                          icon="iconamoon:arrow-up-2"
                          width="1.2em"
                          height="1.2em"
                        />
                      ) : (
                        <Icon
                          // onClick={handleTogglekhadmat}
                          icon="ic:baseline-keyboard-arrow-down"
                          width="1.2em"
                          height="1.2em"
                        />
                      )}
                    </div>
                    الخدمات
                    {/* <Icon icon="majesticons:file-plus" width="1.2em" height="1.2em" /> */}
                    <Icon
                      icon="ic:baseline-plus"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>
                </>
              )}

              <div className={`settingshowdiv ${khadmat ? "visible" : ""}`}>
                {user?.role === "user" ? (
                  <NavLink to="/readanalysis">
                    <div onClick={testfun}> قراءة تحليل </div>
                  </NavLink>
                ) : user?.role === "admin" ? (
                  <></>
                ) : user?.role === "superadmin" ? (
                  <></>
                ) : (
                  <></>
                )}
                {user?.role === "user" ? (
                  <NavLink to="/bookameeting">
                    <div onClick={bookmeetingfun}> لقاء مع الدكتور </div>
                  </NavLink>
                ) : user?.role === "admin" ? (
                  <></>
                ) : user?.role === "superadmin" ? (
                  <></>
                ) : (
                  <></>
                )}

              </div>

              <div onClick={(e) => handleToggleContent(e)}>
                <div className="dropdowniconf">
                  {isContentVisible2 ? (
                    <Icon
                      // onClick={handleToggleContent}
                      icon="iconamoon:arrow-up-2"
                      width="1.2em"
                      height="1.2em"
                    />
                  ) : (
                    <Icon
                      // onClick={handleToggleContent}
                      icon="ic:baseline-keyboard-arrow-down"
                      width="1.2em"
                      height="1.2em"
                    />
                  )}
                </div>
                الاعدادات
                <Icon icon="ic:sharp-settings" width="1.2em" height="1.2em" />
              </div>
              <div
                className={`settingshowdiv ${
                  isContentVisible2 ? "visible" : ""
                }`}
              >
                {
                  user?.role === 'super-admin' ? (
                    <NavLink to="/admintimings">
                      <div>اعدادات المقابلات</div>
                    </NavLink>
                  ) : <></>
                }
                {user?.role === "user" ? (
                  <NavLink to="/accountsetting">
                    <div>اعدادات الحساب</div>
                  </NavLink>
                ) : user?.role === "admin" ? (
                  <NavLink to="/accountsetting">
                    <div>اعدادات الحساب </div>
                  </NavLink>
                ) : user?.role === "super-admin" ? (
                  <NavLink to="/accountsetting">
                    <div> اعدادات الحساب</div>
                  </NavLink>
                ) : (
                  <></>
                )}

                {user?.role === "user" ? (
                  <NavLink to="/changepassward">
                    <div> تغيير كلمة المرور</div>
                  </NavLink>
                ) : user?.role === "admin" ? (
                  <NavLink to="/changepassward">
                    <div> تغيير كلمة المرور</div>
                  </NavLink>
                ) : user?.role === "super-admin" ? (
                  <NavLink to="/changepassward">
                    <div>تغيير كلمة المرور</div>
                  </NavLink>
                ) : (
                  <></>
                )}
              </div>

              {user?.role === "user" ? (
                <NavLink to="/owner">
                  <div>
                    عن المنصة{" "}
                    <Icon icon="raphael:users" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : user?.role === "admin" ? (
                <NavLink to="/owner">
                  <div>
                    عن المنصة{" "}
                    <Icon icon="raphael:users" width="1.2em" height="1.2em" />
                  </div>
                </NavLink>
              ) : user?.role === "superadmin" ? (
                <NavLink to="/create">
                  <div> اعدادات الحساب</div>
                </NavLink>
              ) : (
                <></>
              )}

              <div onClick={gotologinpage}>
                {" "}
                تسجيل الخروج{" "}
                <Icon icon="tabler:logout" width="1.2em" height="1.2em" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="btnbox">
        {user ? (
          <div className="myb">
            {/* <Icon onClick={searchdropdown} className="searchicon" icon="iconamoon:search-bold" width="4rem" height="4rem" /> */}
            {user?.role === "user" && (
              <>
              <div className="meeting-for-user-in-nav">
                <Icon
                  onClick={addameeting}
                  icon="typcn:plus"
                  width="4rem"
                  height="4rem"
                />
                </div>
              </>
            )}
            {user?.role === "admin" && (
              <>
                <Icon
                  onClick={gotonotification}
                  icon="mdi:file"
                  width="1.2em"
                  height="1.2em"
                />
                {/* <img style={{cursor:"pointer"}} onClick={gotonotification} src={navnoti} alt="" /> */}
              </>
            )}
            <div className="maindivnoti">
              <Icon
                onClick={shownotific}
                icon="clarity:notification-solid"
                width="4rem"
                height="4rem"
              />
              {newNotificationsCount > 0 && (
                <div className="show123">{newNotificationsCount}</div>
              )}
            </div>
            <Icon
              onClick={gotheprofilepage}
              icon="mdi:user"
              width="4rem"
              height="4rem"
            />
          </div>
        ) : (
          <div className="bbtt">
            <button className="b1" onClick={gotologin}>
            دخول
            </button>
            <button className="b2" onClick={gotocreateaccount}>
            التسجيل
            </button>
          </div>
        )}
      </div>

      {
        user?.role === 'user' &&
        <div className="nav-mid-btns">
        {/* <div className={navActive==='callus' ? 'nav-mid-btns-btn-active' : ''} onClick={()=>navigate('/callus')}>اتصل بنا</div> */}
        {
          user &&
        <div className={navActive==='service' ? 'nav-mid-btns-btn-active' : ''} onClick={()=>navigate('/readanalysis')}>الخدمات</div>
        }
        <div className={navActive==='home' ? 'nav-mid-btns-btn-active' : ''} onClick={()=>navigate('/')}>الصفحة الرئيسية</div>
      </div>}

      <div className="navlog">
        <img onClick={gotohomescreen} src={logo} alt="" />
        <h2 onClick={gotohomescreen}>د.مازن السقا</h2>
        <div className="UserActiveStts">
          {user?.role === "super-admin" ? (
            <>
              <div onClick={ShowUserSideBar}>
                <Icon icon="mingcute:user-star-fill" />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={userSideBar ? "userssidebar" : "userssidebar2"}>
          {userSideBar ? (
            <div
              className="closing-navbar"
              onClick={() => {
                setUserSideBar(false);
              }}
            ></div>
          ) : (
            <></>
          )}

          <div
            className="usersidebarlist"
            onClick={() => {
              setUserSideBar(true);
            }}
          >
            <UsersSidebar />
          </div>
        </div>

        {user && (
          <>
            <div style={{ marginTop: "1.5rem", color: "#B571AC" }}>
              <Icon
                onClick={tooglesidebar}
                icon="lucide:menu"
                width="2.5rem"
                height="2.5rem"
              />
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
