import React, { useContext, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import webinar from "../../../Asserts/Images/Webinar.png";
import "./events.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import nobook from '../../../Asserts/Images/nobook.png'


function  Events() {
  const navigate = useNavigate();
  const {user} = useContext(UserContext)
  const [videoLoading, setVideoLoading] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [webinars, setWebinars] = useState([]);
  const fetchWebinars = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/getall`);
        setWebinars(response.data.data);
    } catch (error) {
        console.error('Error fetching webinars:', error);
    }
};

useEffect(() => {
    fetchWebinars();
}, []);
  const paginationIncrement = () => {
    if (pagination < totalPages) {
      if (pagination % 2 === 0) {
        setActivePage(activePage + 2);
      }
      setPagination((prevPagination) => prevPagination + 1);
    }
  };

  const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/login')
  }

  const paginationDecrement = () => {
    if (pagination > 1) {
      if ((pagination - 1) % 2 === 0) {
        setActivePage(activePage - 2);
      }
      setPagination((prevPagination) => prevPagination - 1);
    }
  };

  function toEventDetail (id) {
    if(user?.role === 'admin'|| user?.role === 'super-admin'){
    navigate(`/webinarattendants/${id}`)
    }
    else{
      navigate(`/webinarform/${id}`)
    }
  }

  return (
    <div>
      <div>
        <Navbar />
      </div>
      {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      <div>
        <div className="event-heading">الفاعليات</div>
        <div className="webinars-outer">
          <div className="webinars-inner">
            {/* map here */}
            {
              webinars.length === 0 &&
              <div className="nobooksmaindiv">
            <img src={nobook} alt="" />
            آسف لا توجد أحداث حتى الآن
          </div>
            }
            {webinars.map((webinar) => {
              return (
                <div className="w-i-box" key={webinar.id} onClick={() => toEventDetail(webinar._id)}>
                  <div className="w-i-b-img">
                    <img src={webinar.picture} alt="no Picture" />
                  </div>
                  <div className="w-i-title-things">
                    <div className="w-i-tt-title">{webinar.title}</div>
                    <div className="w-i-tt-time">{webinar.date}</div>
                  </div>
                  <div className="w-i-price">{webinar.price} دولار</div>
                  <div className="w-i-price">{webinar.presenter}</div>
                </div>
              );
            })}
          </div>
          <div className="pagination-container">
            {totalPages === 1 || (
              <div
                className={
                  pagination === 1 ? "pagination-disable" : "pagination-enable"
                }
                onClick={paginationDecrement}
              >
                {"<"}
              </div>
            )}
            <div>
              <span
                className={
                  pagination === activePage
                    ? "pagination-active"
                    : "pagination-notactive"
                }
              >
                {activePage}
              </span>
            </div>
            {totalPages === 1 || (
              <div>
                <span
                  className={
                    pagination === activePage + 1
                      ? "pagination-active"
                      : "pagination-notactive"
                  }
                >
                  {activePage + 1}
                </span>
              </div>
            )}
            {totalPages === 1 || (
              <div
                className={
                  pagination === totalPages
                    ? "pagination-disable"
                    : "pagination-enable"
                }
                onClick={paginationIncrement}
              >
                {">"}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Events;
