import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import norequest from './../../../Asserts/Images/norequest.png'
import BranchLoading from '../../User/branchLoading/BranchLoading';
import { useNavigate } from 'react-router-dom';
import './unpaidlabworks.css'

export default function UnpaidLabworks() {
    const { user, token } = useContext(UserContext);
    const [loading,setLoading] = useState(false)
    const [unpaidLabworks, setUnpaidLabworks] = useState([]);
    const [page, setPage] = useState(1);  // current page state
    const [active,setActive] = useState(1)
    const [limit] = useState(10);  // limit per page, fixed at 10 for now
    const [totalPages, setTotalPages] = useState(1);  // total pages

    const navigate = useNavigate()

    // Fetch unpaid lab works with pagination
    const getAllUnpaidLabWorks = async (page) => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/unpaidLabworks`, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                params: { page, limit }
            });

            // console.log('unpaid labworks:', response.data.requests);

            setUnpaidLabworks(response.data.requests);
            setTotalPages(response.data.totalPages);  // update total pages
        } catch (err) {
            // console.log(err);
        }
        setLoading(false)
    };

    useEffect(() => {
        if (user?.role === 'admin') {
            getAllUnpaidLabWorks(page);  // fetch data when page changes
        }
    }, [page, user]);

    // Handle page navigation
    const handleNextPage = () => {
        if (page < totalPages) {
          if((page)%2===0){
            setActive(active+2)
          }
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
          if((page-1)%2===0){
            setActive(active-2)
          }
            setPage(page - 1);
        }
    };

    return (
        <div>
            <div>
                <Navbar/>
            </div>
            {/* <h2>Unpaid Labworks</h2> */}
            {/* {unpaidLabworks.length > 0 ? (
                <ul>
                    {unpaidLabworks.map(labwork => (
                        <li key={labwork._id}>{labwork?.requested_by?.first_name}</li>  // replace someDetail with actual property
                    ))}
                </ul>
            ) : (
                <p>No unpaid labworks found.</p>
            )} */}

            {/* Pagination controls */}
            {/* <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={page === 1}>
                    Previous
                </button>
                <span>Page {page} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={page === totalPages}>
                    Next
                </button>
            </div> */}
            <div className='meetings-none'>
                <div className='unpaid-heading'>
                    <h1>اجتماعات غير مدفوعة الأجر</h1>
                </div>
            {
  loading ? <BranchLoading/> : <>{
    unpaidLabworks && unpaidLabworks.length <= 0 ? <>

<div className="norequestmaindiv">
<img src={norequest} alt="" />
لم تقم بطلب قراءة أي تحليل
</div>

</> : unpaidLabworks.map((request) => {
  // Check if created_at is a valid date
  const requestDate = new Date(request.created_at);

  // Format the time as hh:mm am/pm
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = requestDate.toLocaleTimeString('en-US', timeOptions);

  // Format the date as dd/mm/yyyy
  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = requestDate.toLocaleDateString('en-GB', dateOptions);
  const dayOptions = { weekday: 'long' };
  const formattedDay = requestDate.toLocaleDateString('en-US', dayOptions);
  return (
    <div className="notificationcardsmain" onClick={() => navigate(`/collobies/${request._id}`)}>
      <div className="notificationcardleft" style={{ backgroundColor: '#B571AC' }}>
      غير مدفوعة الأجر
      </div>

      <div className="notificatoncardright">
        <div className="noticardtopdiv">
          <div className="notiardtopleft">
            {request.patient_phone && request.patient_phone ? request.patient_phone : ''}
          </div>

          <div className="noticardtopright">
            <div>{request.patient_name||request.requested_by.first_name+" "+request.requested_by.last_name}</div>
            <div>{request.request_title}</div>
            <div className='givepurplecolor'> {request?.requestTime} {request?.requestDate  } :{request?.requestDay}</div>
          </div>

        </div>

        <div className="noticardbottomdiv">
          {request.request_description}
        </div>
      </div>
    </div>
  );
})
}
{
  unpaidLabworks && unpaidLabworks.length > 0 ?
  <div className="pagination-container">
        <div className={page === totalPages ? 'pagination-enable' : 'pagination-disable'} onClick={handlePrevPage}>
          {'<'}
        </div>
        <div>
          <span className={ page === (active) ? 'pagination-active' : "pagination-notactive"}>{active}</span>
        </div>
        <div>
          <span className={ page === (active+1) ? 'pagination-active' : "pagination-notactive"}>{active + 1}</span>
        </div>
        <div className={page === 1 ? 'pagination-enable' : 'pagination-disable'} onClick={handleNextPage}>
          {'>'}
        </div>
      </div> : <></>
}

</>
}
            </div>
            <div><Footer/></div>
        </div>
    );
}
