import React, { useContext, useEffect, useState } from 'react'
import ProfilePic from './../../../../../Asserts/Images/DummyProfile.png'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ManagersContext } from '../../ManagersManagementContext/ManagersContext'
import Navbar from '../../../../User/Navbar/Navbar'
import Footer from '../../../../User/Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../../../../context/UserContext'
import { useSessionErrorHandler } from '../../../../User/Managesession'
import axios from 'axios'
import Loading from '../../../../User/Loading/Loading'
import './manageractivity.css'
import ManagerDetail from '../ManagerDetail/ManagerDetail'
function ManagerActivity() {

 const [manageractivities,setManagerActivities] = useState([])
  const navigate = useNavigate()
  const [activityloading,setActivityLoading]  = useState(true)
  const {token,loading,setLoading,user} = useContext(UserContext)
  const handleSessionError =  useSessionErrorHandler()
  const [popup,setPopup] = useState(false)
  const [popup1,setPopup1] = useState(false)
  const [adminloading,setAdminLoading] = useState(false)
  const { id } = useParams();
  const [ManagerDetails, setManagerDetails] = useState({});

  const getManagerDetails = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userdetails/${id}`,{headers:{
        authorization:`Bearer ${token}`
      }});
      // console.log("manager details",response)
      setManagerDetails(response.data.data);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      handleSessionError(err);
      setLoading(false);
    }
  };

  function moveBack () {
    navigate('/allmanagers')
  }
  useEffect(()=>{
    if(user?.role==='super-admin'){
    getManagerDetails(id)
    }


  },[])
  function deleteManager(e, id) {
    e.stopPropagation();
    DeleteAdmin(e, id); // Call the function to delete the admin
    setPopup(false); // Close the popup after delete
  }

  function blockManager(e, id) {
    e.stopPropagation();
    handleSuspendUser(e, id); // Suspend the admin
    setPopup1(false); // Close the popup after suspend
  }

  function toSpecialization () {
    navigate(`/managerspecialization/${id}`)
  }


  function managePopup1 () {
    if(popup1){setPopup1(false)}
    else{setPopup1(true)}
  }
  const handleUnsuspendUser = async(e,id)=>{
    e.stopPropagation()
    try{
      setAdminLoading(true)
      const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/UnsuspendAdmin?adminId=${id}`,{},{headers:{
        authorization:`Bearer ${token}`
      }})
      // console.log("unsuspend",response)
      setAdminLoading(false)
      navigate('/allmanagers'); // Navigate after unsuspend
    }catch(err){
      // console.log(err)
      setAdminLoading(false)
      handleSessionError(err)
    }
  }
const handleSuspendUser = async(e,id)=>{
  e.stopPropagation()
  try{
    setAdminLoading(true)
    const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/suspendAdmin?adminId=${id}`,{},{headers:{
      authorization:`Bearer ${token}`
    }})
    // console.log("suspend",response)
    setAdminLoading(false)
    navigate('/allmanagers'); // Navigate after suspend
  }catch(err){
    // console.log(err)
    setAdminLoading(false)
    handleSessionError(err)
  }
}
const DeleteAdmin = async(e,id)=>{
  e.stopPropagation()
  try{
    setAdminLoading(true)
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/admin/deleteAdmin?adminId=${ManagerDetails?._id}`,{headers:{
      authorization:`Bearer ${token}`
    }})
    // console.log("delete",response)
    navigate('/allmanagers'); // Navigate after delete

    setAdminLoading(false)
  }catch(err){
    // console.log(err)
    setAdminLoading(false)
    handleSessionError(err)
  }
}
function handleUnsuspendOrSuspend(e, id, isSuspended) {
  e.stopPropagation();
  if (isSuspended) {
    handleUnsuspendUser(e, id); // Unsuspend the admin
  } else {
    handleSuspendUser(e, id); // Suspend the admin
  }
  setPopup1(false); // Close the popup
}


  function moveForward (managerId,activityId){
    // navigate(`/managerdetail/${activityId}`)
  }

  function managePopup () {
    if(popup){setPopup(false)}
    else{setPopup(true)}
  }
 const  getManagerActivities = async(adminId)=>{
    try{
      setActivityLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/allactivities?adminId=${adminId}`,{headers:{
        authorization:`Bearer ${token}`
      }})
      // console.log("manager activities",response)

      setManagerActivities(response.data.response)
      setActivityLoading(false)

 }catch(err){
  //  console.log(err)
   handleSessionError(err)
   setActivityLoading(false)
 }
}
  useEffect(()=>{
    if(user?.role==='super-admin'){
    getManagerActivities(id)
    }

  },[])
  return (
activityloading?<Loading/>:
    <>
    <div> <Navbar/> </div>
    <div className='manageractivity-main'>
      <div className='manageractivity-nav'>
        <div className='manageractivity-back' onClick={moveBack}>
        <Icon icon="weui:back-filled" />
        </div>
        <div className='manageractivity-nav-text'>
          <div className='managementactivity-nav-name'>{ManagerDetails?.first_name}</div>
          <div>
          <Icon icon="weui:back-outlined" />
          </div>
          <div onClick={moveBack}>
          المديرون
          </div>
        </div>
      </div>
      {popup && (
          <>
            <div className="m-d-c-p-container">
              <div
                className="m-d-bg-black-shadow"
                onClick={() => setPopup(false)}
              ></div>
              <div className="m-d-cancel-popup">
                <div className="m-d-c-p-ttext">
                {`${ManagerDetails?.last_name} ${ManagerDetails?.first_name}`}  هل أنت متأكد انك تريد حذف المساعد الطبي

                </div>
                <div className="m-d-c-p-btn">
                  <button onClick={deleteManager}>نعم</button>
                </div>
              </div>
            </div>
          </>
        )}
        {popup1 && (
          <>
            <div className="m-d-c-p-container">
              <div
                className="m-d-bg-black-shadow"
                onClick={() => setPopup1(false)}
              ></div>
              <div className="m-d-cancel-popup">
                <div className="m-d-c-p-ttext">
                {`${ManagerDetails?.last_name} ${ManagerDetails?.first_name}`}  هل أنت متأكد انك تريد تعليق المساعد الطبي

                </div>
                <div className="m-d-c-p-btn">
  <button onClick={(e) => handleUnsuspendOrSuspend(e, ManagerDetails?._id, ManagerDetails?.blocked)}>
    {ManagerDetails?.blocked ? 'إلغاء تعليق المشرف' : 'ايقاف المشرف'}
  </button>
</div>

              </div>
            </div>
          </>
        )}
      <div className='manageractivity-body'>
        <div className='manageractivity-body-managerprofile'>
          <div className='m-a-d-about-manager'>
            <div className='m-a-d-a-m-upper'>
              <div className='m-a-d-a-m-u-pic'>
                { ManagerDetails?.photo ?
                 <img src={ManagerDetails?.photo} alt='' />
                 : <img src={ProfilePic} alt='' />
                }
              </div>
              <div className='m-a-d-a-m-u-name'>
              {`${ManagerDetails?.last_name} ${ManagerDetails?.first_name}`}
              </div>
              <div className='m-a-d-a-m-u-title'>
                المسمى الوظيفي
                :{ManagerDetails?.jobTitle}
              </div>
              <div className='m-a-d-a-m-u-date'>
              {new Date(ManagerDetails?.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              })} :تاريخ الانضمام
              </div>
            </div>
            <div className='m-a-d-a-m-mid-btns'>
              <div className='m-a-d-a-m-mb-btn'>
                <button onClick={managePopup}>حذف</button>
              </div>
              <div className='m-a-d-a-m-mb-btn'>
                <button onClick={managePopup1}>
                  {ManagerDetails?.blocked ? 'إلغاء التعليق' : 'تعليق'}
                </button>
              </div>
              <div className='m-a-d-a-m-mb-btn'>
                <button onClick={toSpecialization}>الاختصاص</button>
              </div>
            </div>
            <div className='m-a-d-a-m-down'>
              <div className='m-a-d-a-m-d-heading'>الاختصاص</div>
              <div>
                <ul className='m-a-d-a-m-ul'>
                 {ManagerDetails?.ableToUploadBannerAndVideos? <li>الفيديوهات والاعلانات الدعائية</li>:""}
                  {ManagerDetails?.ableToAttendMeetings?<li>تنظيم المقابلات مع الدكتور</li>:""}
                  {ManagerDetails?.ableToAttendLabworks?<li>استلام التحاليل والرد عليها</li>:""}
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div className='manageractivity-body-activity'><div>النشاط</div></div>
        <div className='manageractivity-body-activities'>
          <div className='manageractivity-body-activities-container'>
            {
              manageractivities?.length > 0 ? manageractivities.map((item) => {


                return (
                  <div className='manageractivity-body-activities-box' onClick={() => moveForward(item?.userId?._id,item._id)}>
                    <div className='manageractivity-body-activities-dateandtime'>{`${item.date} : ${item.day.substring(0,3)}`}</div>
                    <div className='manageractivity-body-activities-things'>
                      <div>{item.firstLoginTime} : اول ظهور</div>
                      <div>{item.totalUploadedVideos} : الفيديوهات المرفوعة</div>
                      <div>{item.totalModifications} : التعديلات</div>
                      <div>{item.lastLogoutTime} : اخر ظهور</div>
                    </div>
                  </div>
                );
              }) : null
            }

          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer/>
    </div>
    </>
  )
}

export default ManagerActivity
