import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import bookimage from '../../../Asserts/Images/book.svg';
import './Buyedbook.css';
import novideo from '../../../Asserts/Images/novideos.png'
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
// Sample data for books
import { useSessionErrorHandler } from '../Managesession';
import BranchLoading from '../branchLoading/BranchLoading';
function Buyedbook() {
  const {user,token,setUsersession,} = useContext(UserContext)
  const [loading,setLoading] = useState(false)

  const handleSessionError = useSessionErrorHandler();
  const downloadFileFromS3 = async (fileKey) => {
    // navigate(`/pdfiframe/${fileKey}`)
//     try {
//       // Fetch the signed URL from the backend
//       const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/s3/downloadpdf?fileKey=${encodeURIComponent(fileKey)}`, {
//         headers: {
//           authorization: `Bearer ${token}`, // Replace with your auth token logic
//         },
//       });
//  console.log('signed url',response)
//       if (!response.ok) throw new Error('Failed to fetch signed URL');

//       const { downloadUrl } = await response.json();

//       // Download the file using the signed URL
//       const downloadResponse = await fetch(downloadUrl);
//       if (!downloadResponse.ok) throw new Error('Network response was not ok');

//       const blob = await downloadResponse.blob();
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = url;
//       link.download = `Mazen Book`; // Customize the filename if needed
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       window.URL.revokeObjectURL(url); // Clean up after download
//     } catch (error) {
//       console.error('Error downloading file:', error);
//       alert('Failed to download file. Please try again.');
//     }
  };
  const downloadBook = async (bookUrl) => {
    // navigate(`/pdfiframe/${bookId}`)
    const fileKey = bookUrl.split('.com/')[1];
    downloadFileFromS3(fileKey)

  };

  const [books,setBooks] = useState([]);
  const navigate = useNavigate();
  const getBoughtBooks = (async()=>{
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/book/allbuyedBooks/${user._id}`,{headers:{
      authorization: `Bearer ${token}`,
    }}).then((response)=>{
      // console.log("buyed",response);
      setBooks(response.data.response);

    }).catch((err)=>{
      // console.log(err);
      setBooks([]);
      handleSessionError(err);


    })
  })
  useEffect(()=>{
    getBoughtBooks();
  },[])
  return (
    <>
      <div className="buyedbookmain">
        <div className="buyedbooknav">
          <Navbar />
        </div>
        <div className="buybookcontainer">
          <div className="buybooktitlediv">كتبي</div>
          <div className="bookscontainermaindive">

{
  loading ? <BranchLoading/> :
  books.length > 0 ?
    books.map((book) => (
      <div key={book._id} onClick={()=>navigate(`/buybook/${book._id}`)} className="bookmaindivfrontend">
        <div className="imagedivbook">
          <img src={book?.bookCoverPhoto} alt={book.bookTitle} />
        </div>
        <div>{book.bookTitle}</div>
        <div>من تأليف: {book.author}</div>
        <div>{book.book_description}</div>
        <div className="buttondivbookbuyed">
          <button onClick={() => downloadBook(book.bookPDF)}>يقرأ</button>
        </div>
      </div>
    ))
  :
  <div className="courcesmaindiv">
    <img src={novideo} alt="" />
  </div>
}

          </div>
        </div>
        <div className="buyedbookfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Buyedbook;
