import React, { useContext, useEffect, useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { PDFDownloadLink } from '@react-pdf/renderer'
import './Paymentsuccess.css'
import PaymentTemplate from '../../User/Sucessmessage/Payment Succeed/PaymentTemplate'
import { socket } from '../../User/Bloodimageanalysis/globalsocket'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'
import { useSessionErrorHandler } from '../../User/Managesession'
import Loading from '../../User/Loading/Loading'
import { getCurrentDate, getCurrentTime, getCurrentTimeISO } from '../../../HelperFunctions'
function PaymentSuccess2() {
    const [show,setShow] = useState(false)
    const {loading,setLoading,token} = useContext(UserContext);
    const handleSessionError = useSessionErrorHandler();
    const [paymentdata, setpaymentdata] = useState({});
    const {user}  = useContext(UserContext);
 const navigate = useNavigate()
    const {id} = useParams();
    const gettranscitionbyId = async () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/${id}`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        }).then((response) => {
        //   console.log('paymentdata', response.data.payment);
          setpaymentdata(response.data.payment);
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          handleSessionError(err);
        //   console.log(err);
        });
      };
    const handleNavigate = (e) => {
        e.preventDefault();
        if (paymentdata) {
            if (paymentdata.request_id?._id) {
                navigate(`/patientreqdetails/${paymentdata.request_id._id}`);
            } else if (paymentdata.book_id?._id) {
                navigate(`/buybook/${paymentdata.book_id._id}`);
            } else if (paymentdata.meeting_id?._id) {
                const socketData = {
                    Message: `مطلوب لاجتماع ${user.first_name}`,
                    Link: `/Interviewdetail/${paymentdata.meeting_id?._id}`,
                    Deliver_To: "admin",
                    time: getCurrentTime(),
                    date: getCurrentDate(),
                    created_at:getCurrentTimeISO()
                  };

                  socket.emit("notification", socketData);
                navigate(`/Interviewdetail/${paymentdata.meeting_id._id}`);
            }
            else if(paymentdata?.form_id?.webinar_id){
                navigate(`/webinarform/${paymentdata.form_id.webinar_id}`)
            }
        }
    };

      useEffect(() => {
        // console.log(id);
        gettranscitionbyId();
      }, []);
  return (
    loading?<Loading/>:
    <div>

      <div className='paymentsuccess-container'>

        {paymentdata?.transcition_status==="Success"?<div className='paymentsuccess-successmessage'>
            <div className='success-icon'>
              <Icon icon="hugeicons:tick-02" width="1.2em" height="1.2em" />
            </div>
            <div className='success-message'>
            تم الدفع بنجاح
            </div>
           {paymentdata.book_id?._id?" ": <div className='success-message2'>
            سيتم التواصل معكم عبر الرسائل النصية
و خدمة التنبيهات في الموقع لأجل
خطوات المتابعة
            </div>}
        </div>:paymentdata?.transcition_status==="Declined" ?<div className='paymentsuccess-failmessage'>
            <div className='fail-icon'>
                <Icon icon="fxemoji:crossmark" width="1.2em" height="1.2em" />
                </div>
                <div className='fail-message'>
                    فشل الدفع
                    </div>
                    <div className='fail-message2'>
                        يرجى المحاولة مرة أخرى
                        </div>
                        </div>:""}
        <div className='payment-data'>
            <div className='showmore-success' onClick={()=>{setShow(!show)}}>
                <div className='sidedotted-line'></div>
                <div>عرض الفاتورة </div>
                <div>
                    {
                        show ?  <Icon icon="mingcute:up-line" />: <Icon icon="mingcute:down-line"/>
                    }
                    </div>
                <div className='sidedotted-line'></div>
            </div>
            {
                show ? <>
<div className='success-bill-details'>
                <div>
                    <h1>{paymentdata.transcition_amount} SR</h1>
                </div>
                <div className='dotted-line'></div>
                <div className='data-section'>
                    <div className='line-data'>
                        <div className='first-element'>المشتري</div>
                        <div>{paymentdata?.transcition_by?.first_name+" "+paymentdata?.transcition_by?.last_name}</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>البائع</div>
                        <div>{paymentdata?.seller_information}</div>
                    </div>
                </div>
                <div className='dotted-line'></div>
                <div className='data-section'>
                    <div className='line-data'>
                        <div className='first-element'>المبلغ</div>
                        <div>{paymentdata.transcition_amount}</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>رسوم إضافية</div>
                        <div>0</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>الرقم المرجعي</div>
                        <div>{paymentdata?.transcition_id}</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>التاريخ</div>
                        <div>{paymentdata?.transcitionDate}</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>الوقت</div>
                        <div>{paymentdata?.transcitionTime}</div>
                    </div>
                    {/* <div className='line-data'>
                        <div className='first-element'>وسيلة الدفع</div>
                        <div>200</div>
                    </div> */}
                </div>
                <div className='dotted-line'></div>

               {paymentdata?.transcition_status==='Success'? <PDFDownloadLink
                    document={ <PaymentTemplate paymentdata={paymentdata}/> }
                    fileName={`فاتورة-${paymentdata?.transcition_id}.pdf`}
                    className='link'
                    ><div className='reciept-download'>
                        <div><Icon icon="hugeicons:download-01"/></div>
                        <div>تحميل الفاتورة</div>
                        </div>
                    </PDFDownloadLink>:""}



                <div className='bold-line'></div>
            </div>
                </> : <></>
            }

        </div>
       {paymentdata?.transcition_status==="Success" ?<div className='end-button'>
            <button onClick={(e)=>handleNavigate(e)} >تم</button>
        </div>:""}
      </div>
    </div>
  )
}

export default PaymentSuccess2