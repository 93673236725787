import React, { useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import novideo from "../../../Asserts/Images/novideos.png";
import drimg from "../../../Asserts/Images/drimg.svg";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { useSessionErrorHandler } from "../Managesession";

function Videos({ activeButton, setChangetab }) {
  const handleSessionError = useSessionErrorHandler();
  const { user, token } = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [deletepopup, setDeletepopup] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [popupIndex, setPopupIndex] = useState(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const [activeButton2, setActiveButton2] = useState("");
  const [pagination, setPagination] = useState(1);
  const [paginationcontrol, setPaginationcontrol] = useState(1);
  const [activePage,setActivePage] = useState(1)
  const [subcategories, setSubCategories] = useState([]);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(0);
  const [deleteReason, setDeleteReason] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const paginationIncrement = () => {
    if (pagination < totalPages) {
      if((pagination)%2===0){
        setActivePage(activePage+2)
      }
      setPagination((prevPagination) => prevPagination + 1);

    }
  };

  const paginationDecrement = () => {
    if (pagination > 1) {
      if((pagination-1)%2===0){
        setActivePage(activePage-2)
      }
      setPagination((prevPagination) => prevPagination - 1);
    }
  };

  const handleButtonClick2 = (name) => {
    // console.log("name in active button", name);
    setActiveButton2(name);
    fetchVideos(name);
  };
  const showdelete = (e, id) => {
    e.stopPropagation();
    setDeletepopup(!deletepopup);
    setDeletedId(id);
  };

  const [videoData, setVideoData] = useState(null);
  const getYouTubeVideoID = (url) => {
    const urlObj = new URL(url);
    const videoID = urlObj.searchParams.get("v");
    if (videoID) return videoID;
    const pathSegments = urlObj.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  };

  const fetchVideoData = async (videoUrl) => {
    const videoId = getYouTubeVideoID(videoUrl);
    // console.log('hello')
    try {
      const response = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`);
      const data = await response.json();
      // console.log(data)
      return `${data}`

    } catch (error) {

    }
  }

  const showpopup = (e, index) => {
    e.stopPropagation();
    setPopupIndex(popupIndex === index ? null : index);
  };

  const navigatesuccess = async (e, courseID) => {
    e.stopPropagation();
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/video/updateToHidden/${courseID}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      // Set the tab and fetch videos after the status has been changed
      // setChangetab("عام");
      fetchVideos(activeButton2);
      setPopupIndex(null)
    } catch (err) {
      handleSessionError(err);
      // console.log(err);
    }
  };
  const fetchSubCategories = async (section) => {
    try {
      setCategoryLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/section/getsubsectionsbysectionname?section=${section}`
      );
      const subCategoriesData = response.data.response;

      setSubCategories(subCategoriesData);
      if (subCategoriesData.length > 0) {
        setActiveButton2(subCategoriesData[0].title);
        setCategoryLoading(false);

      }
    } catch (err) {
      // console.log(err);
    }
  };
  useEffect(() => {
    setPagination(1);
  }, [activeButton2]);

  useEffect(() => {
    fetchVideos(activeButton2);
  }, [activeButton2, pagination]);

  const gotouploadvideo = (e, id) => {
    e.stopPropagation();
    navigate(`/uploadvideo/${id}`);
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      // First fetch subcategories
      await fetchSubCategories(activeButton);

      // Then fetch videos based on the first subcategory
      if (subcategories.length > 0) {
        await fetchVideos(subcategories[0]?.title);
      }
    };
    fetchInitialData();
  }, [activeButton]);
  const fetchVideos = async (subSection) => {
    setVideoLoading(true);
    if(subcategories.length>0){


    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/video/getVideosBySectionAndSubSections`,
        {
          params: {
            section: "الفيديوهات",
            subSection,
            page: pagination, // Send the current page number
            limit: 8, // Set the limit per page
          },
          headers: { authorization: `Bearer ${token}` },
        }
      );
      setTotalPages(response.data.totalPages);
      setCourses(response.data.response);
      setVideoLoading(false);
      setPaginationcontrol(response.data.currentPage); // Update current page
    } catch (err) {
      setVideoLoading(false);
      // console.error(err);
    }
  }
  else{
    // console.log('loading subcategories')
  }
  };

  const disableVideo = async (e, id) => {
    try {
      setDeletepopup(false);
      setVideoLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/video/videoDisable/${deletedId}?disableBy=${user?._id}`,{disableReason:deleteReason},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      fetchVideos(activeButton2);
      setVideoLoading(false);
    } catch (err) {
      setVideoLoading(false);
      handleSessionError(err);
      // console.log(err);
    }

  };

  const performdeleteaction = async () => {
    if (deleteReason.length > 0 && user?.role === "admin") {
      disableVideo();
      setDeletedId(null)
      setPopupIndex(null)
    }
  };

  const unhidevideo = async (e, id) => {
    e.stopPropagation();
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/video/updateFromHiddenToShow/${id}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      fetchVideos(activeButton2);
      setChangetab("عام");
    } catch (err) {
      handleSessionError(err);
      // console.log(err);
    }
  };

  const getEmbedUrl = (url) => {
    if (!url) return ""; // Early return if URL is null or undefined

    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      // Return the URL for the default YouTube video thumbnail
      return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
    } else {
      return ""; // Return empty string if the URL is not a YouTube video
    }
  };

  return (
    <>
      {categoryLoading ? (
        <div className="LoadingInFetchingBooksContainer">
          <div className="LoadingInFetchingBooks">
            <Icon
              icon="eos-icons:bubble-loading"
              width="1.2em"
              height="1.2em"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="btnmaindiv">
            {subcategories.length > 0 &&
              subcategories.map((subcategory, index) => (
                <button
                  key={index}
                  className={
                    activeButton2 === subcategory.title ? "active" : ""
                  }
                  onClick={() => handleButtonClick2(subcategory.title)}
                >
                  {subcategory.title}
                </button>
              ))}
          </div>
          {deletepopup && (
            <div className="deletemaindiv">
              <div className="deletecontainer">
                <div className="deletetitle">
                  <div>سبب الحذف</div>
                  <div
                    className="CloseHadafPopup"
                    onClick={() => setDeletepopup(false)}
                  >
                    x
                  </div>
                </div>
                <textarea
                  placeholder="مختلف عن موضوع القسم"
                  value={deleteReason}
                  onChange={(e) => setDeleteReason(e.target.value)}
                />
                <button onClick={performdeleteaction}>
                  <Icon
                    icon="ic:baseline-delete"
                    width="1.2em"
                    height="1.2em"
                  />{" "}
                  حذف
                </button>
              </div>
            </div>
          )}
        {videoLoading ? (
  <div className="LoadingInFetchingBooksContainer">
    <div className="LoadingInFetchingBooks">
      <Icon
        icon="eos-icons:bubble-loading"
        width="1.2em"
        height="1.2em"
      />
    </div>
  </div>
) : (
          <div className="videosmaincontierflex">
            {courses.length === 0 ? (
              <div className="courcesmaindiv">
                <img src={novideo} alt="" />
              </div>
            ) : (
              <><div className="videosmaincontierflex1">
                {courses.map((course, index) => (
                  <div
                    key={index}
                    className="videointhedivmain"
                    onClick={() => navigate(`/videos/${course._id}`)}
                  >
                    <div className="videoimageleft">
                      <div className="vidoedivshow">
                        {course.youtubeVideoUrl ? (
                          <img
                            src={getEmbedUrl(course.youtubeVideoUrl)}
                            alt="YouTube Thumbnail"
                            width="100%"
                            height="auto"
                          />
                        ) : course.videoUrl ? (
                          <video
                            src={course.videoUrl}
                            width="100%"
                            height="auto"
                          ></video>
                        ) : (
                          <img src="..." alt="No Thumbnail" />
                        )}
                      </div>

                      {/* <video src={course.videoUrl}></video> */}
                    </div>
                    <div className="videonamedesright">
                      <div className="videonameandicon">
                        {user && (((user.role === "admin")&&(user.ableToUploadBannerAndVideos))||(user.role === "super-admin")) && (
                          <>
                            <div>
                              <Icon
                                onClick={(e) => showpopup(e, index)}
                                icon="bi:three-dots-vertical"
                                width="1.2em"
                                height="1.2em"
                              />
                            </div>
                            {popupIndex === index && (
                              <div className="popupmain">
                                <div
                                  onClick={(e) =>
                                    gotouploadvideo(e, course._id)
                                  }
                                >
                                  تعديل
                                </div>
                                {course.hidden ? (
                                  <div
                                    onClick={(e) => unhidevideo(e, course._id)}
                                  >
                                    إظهار
                                  </div>
                                ) : (
                                  <div
                                    onClick={(e) =>
                                      navigatesuccess(e, course._id)
                                    }
                                  >
                                    إخفاء
                                  </div>
                                )}
                                <div onClick={(e) => showdelete(e, course._id)}>
                                  حذف
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        <div className="videoTitle">{course.title}</div>

                      </div>
                      {/* <div className="vid-duration">{fetchVideoData(course.youtubeVideoUrl)}</div> */}
                      <div className="timeofvideoup">{course.timeString}</div>
                      <div className="descrationofvideo">
                        {course.description
                          .split(" ")
                          .slice(0, 50)
                          .join(" ")}
                      </div>
                    </div>
                  </div>
                ))}</div>

                    <div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"

                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>
              </>
            )}
            
          </div>
      )}
        </>
      )}
    </>
  );
}

export default Videos;
