
import './App.css';
import 'react-phone-input-2/lib/style.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserContext } from './context/UserContext';
import Login from './Components/User/Login/Login';
import Register from './Components/User/Register/Register';
import Sucessmessage from './Components/User/Sucessmessage/Sucessmessage';
// import PaySuccessmessage from './Components/User/Sucessmessage/PaySuccessmessage';
import Error from './Components/User/Sucessmessage/Error';
import Verification from './Components/User/Verification/Verification';
import PassVerification from './Components/User/Verification/PassVerification';
import Signupform from './Components/User/SignupForm/Signupform';
import Dashboard from './Components/User/Dashboard/Dashboard';
import Userprofile from './Components/User/UserProfile/Userprofile';
import Book from './Components/User/Book/Book';
import Readanalysis from './Components/User/Readanalysis/Readanalysis';
import Interviewdetail from './Components/User/InterviewDetail/Interviewdetail';
import Mymeeting from './Components/User/Mymeeting/Mymeeting';
import Resetpas from './Components/User/Resetpasward/Resetpas';
import Changepassword from './Components/User/Changepasward/Changepassword';
import ResetChangepassword from './Components/User/Changepasward/ResetChangepassword';
import Account from './Components/User/Accountsetting/Account';
import Callusform from './Components/User/Callus_form/Callus_form';
import Owner from './Components/User/Owner/Owner';
import Usingpolicy from './Components/User/Owner/Usingpolicy';
import Video from './Components/User/Videoshow/Video';
import Buybook from './Components/User/Buyabook/Buybook';
import Myanalytics from './Components/User/myanalytics/Myanalytics';
import Bloodimageanlysis from './Components/User/Bloodimageanalysis/Bloodimageanlysis';
import Paymentdetail from './Components/User/Paymentdetail/Paymentdetail';
import Paymenthis from './Components/User/Paymentshis/Paymenthis';
import TextChecknoti from './Components/Admin/TestChecknoti/TextChecknoti';
import Collywobbles from './Components/Admin/Collwobies/Collowabies';
import Loading from './Components/User/Loading/Loading';
import Mainerror from './Components/User/Mainerror/Mainerror';
import ProtectedRoute from './Components/User/Protectedr';
import Adress from './Components/User/Adress/Adress';
import Addadress from './Components/User/Addadress/Adress';
import Bookmeeting from './Components/User/Readanalysis/Bookmeeting';
import Uploadvideo from './Components/Admin/Uploadvideo/Uploadvideo';
import Uplaodbook from './Components/Admin/Uploadbook/Uplaodbook';
import Newvideo from './Components/Admin/Addbewvideo/Newvideo';
import Uplaodnewbook from './Components/Admin/uploadnewbook/Uplaodbook';
import AdminCategory from './Components/Admin/Catageries/Catageries';
import Adminaddcategory from './Components/Admin/Addcatageries/Addcatageries';
import Adminsubcategory from './Components/Admin/Subcatageries/Subcatageries';
import Adminaddsubcategory from './Components/Admin/Addsubcatageries/Addsubcatageries';
import Communication from './Components/Admin/Communationsetting/Communication';
import Meetingnotify from './Components/Admin/Meetingnotification/Meetingnotify';
import Videouploading from './Components/Admin/Videouploading/Videouploading';
import Bookuploading from './Components/Admin/Bookuploading/Bookuploading';
import Buyedbook from './Components/User/Buyedbooks/Buyedbook';
import Slider from './Components/User/Slider/Slider';
import Uploadbanner from './Components/Admin/Uploadbanner/Uploadbanner';
import Updatebanner from './Components/Admin/Updatebanner/Updatebanner';
import Sessionexpire from './Components/Admin/Sessionexpire/Sessionexpire';
import Networkerror from './Components/User/Networkerror/Networkerror';
import Paymentsuccess from './Components/Admin/Payementsuccess/Paymentsuccess';
import RestrictionsRules from './Components/User/Restrictions to routes/RestrictionsRules';
import AddingAdmin from './Components/Super Admin/AddingAdmin/AddingAdmin';
import ManagersMain from './Components/Super Admin/Manager Screen/ManagersMain';
import LatestMods from './Components/Super Admin/WholeLatestModifications/LatestMods';
import WithoutVerification from './Components/User/Restrictions to routes/WithoutVerification';
import { ManagersProvider } from './Components/Super Admin/Manager Screen/ManagersManagementContext/ManagersContext';
import AllManagers from './Components/Super Admin/Manager Screen/ManagersManagementComponents/AllManagers/AllManagers';
import ManagerActivity from './Components/Super Admin/Manager Screen/ManagersManagementComponents/ManagerActivity/ManagerActivity';
import ManagerDetail from './Components/Super Admin/Manager Screen/ManagersManagementComponents/ManagerDetail/ManagerDetail';
// import PaymentSuccess from './Components/User/Sucessmessage/Payment Succeed/PaymentSuccess';
// import PaymentSuccess2 from './Components/User/Sucessmessage/Payment Succeed/PaymentSuccess2';
import UnpaidLabworks from './Components/Admin/UnpaidLabworks/UnpaidLabworks';
import PaymentStatus from './Components/Admin/PaymentStatus/PaymentStatus';
import TotalUser from './Components/Super Admin/TotalUsers/TotalUser';
import UserInfo from './Components/Super Admin/UserInfo/UserInfo';
import InterviewManagement from './Components/Super Admin/InterviewManagement/InterviewManagement';
import ManagerSpecialization from './Components/Super Admin/Manager Screen/ManagersManagementComponents/ManagerSpecialization/ManagerSpecialization';
import MeetingDetails from './Components/User/UserMeetings/MeetingDetails';
import PostponeMeeting from './Components/User/UserMeetings/PostponeMeeting/PostponeMeeting';
import NoLabworks from './Components/Admin/AdminPermissionsHandling/NoLabworks/NoLabworks';
import NoMeetings from './Components/Admin/AdminPermissionsHandling/Nomeetings/NoMeetings';
import Ifrm from './Components/User/PDF view/PdfShow'
import Webinar from './Components/User/Webinar/Webinar';
import CreateWebinar from './Components/User/Webinar/CreateWebinar';
import UpdateWebinar from './Components/User/Webinar/UpdateWebinar';
import ThankYouPage from './Components/User/Webinar/ThankYouPage';
import Events from './Components/User/Events/Events';
import WebinarDetails from './Components/User/Webinar/WebinarDetails';
import WebinarAttendants from './Components/User/registeredForWebinar/WebinarAttendants';
import WebinarForm from './Components/User/WebinarFomr/WebinarForm';
import BookedEvents from './Components/User/Events/BookedEvents';

function App() {
  const {usersession, setShowsidebar, setNotification, showsidebar, notification, user } = useContext(UserContext);

  const nonesidenoti = () => {
    if (showsidebar === true) {
      setShowsidebar(false);
    }
    if (notification === true) {
      setNotification(false);
    }
  };

  return (
    <>
      <div onClick={nonesidenoti} className='testfontfamily'>
        <BrowserRouter>
       {usersession && <> <Sessionexpire/></>}
          <Routes>

            <Route path='/webinarattendants/:id' element={<WebinarAttendants/>} />
            <Route path='/webinar' element={<Webinar/>} />
            <Route path='/events' element={<Events/>} />
            <Route path='/bookedevents' element={<BookedEvents/>} />

            <Route path='/webinarform/:id' element={<WebinarForm/>} />
            <Route path='/webinar/detail/:id' element={<WebinarDetails/>} />
            <Route path='/thankyoupage' element={<ThankYouPage/>} />
            <Route path='/edit-webinar/:id' element={<UpdateWebinar/>} />
            <Route path='/createEvents' element={<CreateWebinar/>} />
          <Route path='/Paymentsuccess/:id' element={
            <RestrictionsRules><Paymentsuccess /></RestrictionsRules>
            } />
            <Route path='/pdfiframe/:id' element={ <Ifrm/> }/>
          <Route path='/networkerror' element={<Networkerror />} />
          {/* <Route path='/slider' element={<Slider />} /> */}
            <Route path='/loading' element={<Loading />} />
            <Route path='*' element={<Mainerror />} />
            <Route path='/login' element={<Login />} />
            {/* <Route path='/paymentsuccessrequest' element={<PaymentSuccess/>} /> */}
            <Route path='/owner' element={
              <RestrictionsRules><Owner /></RestrictionsRules>
            } />
            <Route path='/usingpolicy' element={<Usingpolicy />} />
            <Route path='/success' element={<Sucessmessage />} />
            {/* <Route path='/paymentsuccess' element={<RestrictionsRules>
              <PaySuccessmessage />
            </RestrictionsRules>} /> */}
            <Route path='/error' element={<Error />} />
            <Route path='/verification' element={<Verification />} />
            <Route path='/passverification' element={<PassVerification />} />
            <Route path='/signupfrom' element={
              <WithoutVerification>
                <Signupform />
              </WithoutVerification>

              } />
              <Route path='/postponemeeting/:interviewID/:zoomMeetingId' element={
                <RestrictionsRules>
                  <PostponeMeeting/>
                </RestrictionsRules> }/>
            <Route path='/' element={<Dashboard />} />
            <Route path='/unpaidlabworks' element={<ProtectedRoute allowedRoles={['admin']}><UnpaidLabworks/></ProtectedRoute>} />

            <Route path='/videos/:videoID' element={<Video />} />
            <Route path='/userprofile' element={<RestrictionsRules>
              <Userprofile />
            </RestrictionsRules>} />
            <Route path='/book' element={<Book />} />
            <Route path='/readanalysis' element={
              <RestrictionsRules>
              <Readanalysis />

            </RestrictionsRules>
          } />
            <Route path='/bookameeting' element={
              <RestrictionsRules>
              <Bookmeeting />
            </RestrictionsRules>} />
            <Route path='/mymeeting' element={<RestrictionsRules>
              <Mymeeting />
            </RestrictionsRules>} />

            <Route path='/Interviewdetail/:interviewID' element={<RestrictionsRules>
              <NoMeetings>
                <Interviewdetail/>
              </NoMeetings>
            </RestrictionsRules>} />
            <Route path='/callus' element={<Callusform />} />
            <Route path='/resetpassword' element={<Resetpas />} />
            <Route path='/changepassward' element={<Changepassword />} />
            <Route path='/resetchangepassward' element={< ResetChangepassword/>} />
              <Route path='/accountsetting' element={<RestrictionsRules>
                <Account />
              </RestrictionsRules>} />
            <Route path='/myanalytics' element={<RestrictionsRules>
              <Myanalytics />
            </RestrictionsRules>} />
            <Route path='/paymenthistory' element={<RestrictionsRules>
              <Paymenthis />
            </RestrictionsRules>} />
            <Route path='/paymentdetail/:id' element={<RestrictionsRules>
              <Paymentdetail />
            </RestrictionsRules>} />

            <Route path="/patientreqdetails/:requestId" element = {<RestrictionsRules>
              <Bloodimageanlysis/>
            </RestrictionsRules>} />
            <Route path="/buybook/:bookId" element={
              <Buybook />
            }/>
            <Route path="/buyedbooks" element={<ProtectedRoute allowedRoles={['user']}><Buyedbook/> </ProtectedRoute>}/>
<Route
        path='/myanalyticsadmain'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <TextChecknoti />
          </ProtectedRoute>
        }
      />
      <Route
        path='/collobies/:requestId'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <NoLabworks>
            <Collywobbles />
            </NoLabworks>
          </ProtectedRoute>
        }
      />
      <Route
        path='/uploadvideo/:id'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Uploadvideo />
          </ProtectedRoute>
        }
      />
      <Route
        path='/uploadbook/:id'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Uplaodbook />
          </ProtectedRoute>
        }
      />
      <Route
        path='/uploadnewvideo/:section/:subSection'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Newvideo />
          </ProtectedRoute>
        }
      />
      <Route path='/transcitionStatus' element={
        <ProtectedRoute allowedRoles={['admin','super-admin']}>
        <PaymentStatus/>
        </ProtectedRoute>} />

      <Route
        path='/uploadnewbook/:section'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Uplaodnewbook />
          </ProtectedRoute>
        }
      />
      <Route
        path='/catageries'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>

            <AdminCategory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/addcatageries'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Adminaddcategory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/subcatageries/:sectionId'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Adminsubcategory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/addsubcatageries'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Adminaddsubcategory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/communation'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Communication />
          </ProtectedRoute>
        }
      />
      <Route
        path='/meetingnotify'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Meetingnotify />
          </ProtectedRoute>
        }
      />
      <Route
        path='/uploadedvideos'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Videouploading />
          </ProtectedRoute>
        }
      />
      <Route
        path='/uploadbanner'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Uploadbanner />
          </ProtectedRoute>
        }
      />
      <Route
        path='/updatedbanner/:id'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Updatebanner />
          </ProtectedRoute>
        }
      />
      <Route
        path='/uploadedbooks'
        element={
          <ProtectedRoute allowedRoles={['admin','super-admin']}>
            <Bookuploading />
          </ProtectedRoute>
        }
      />

      <Route path='addingmanager' element={<ProtectedRoute allowedRoles={['super-admin']}>
        <AddingAdmin/>
      </ProtectedRoute>}  />

      <Route path='/allmanagers' element = {
        <ProtectedRoute allowedRoles={['super-admin']}>
          <ManagersProvider>
            <AllManagers/>
          </ManagersProvider>
        </ProtectedRoute>
      } />

<Route path='/manageractivity/:id' element = {
        <ProtectedRoute allowedRoles={['super-admin']}>
          <ManagersProvider>
            <ManagerActivity/>
          </ManagersProvider>
        </ProtectedRoute>
      } />

{/* <Route path='/managerdetail/:idactivity' element = {
        <ProtectedRoute allowedRoles={['super-admin']}>
          <ManagersProvider>
            <ManagerDetail/>
          </ManagersProvider>
        </ProtectedRoute>
      } /> */}

      <Route path='latestmods' element={
        <ProtectedRoute allowedRoles={['super-admin']}>
          <LatestMods/>
        </ProtectedRoute>
      } />

      <Route path='/allusers' element={
        <ProtectedRoute allowedRoles={['super-admin','admin']}>
          <TotalUser/>
        </ProtectedRoute>
      } />

      <Route path='/userinfo/:userId' element={
        <ProtectedRoute allowedRoles={['super-admin','admin']}>
          <UserInfo/>
        </ProtectedRoute>
      } />

      <Route path='/admintimings' element={
        <ProtectedRoute allowedRoles={['super-admin']}>
          <InterviewManagement/>
        </ProtectedRoute>
      }/>

       <Route path='/managerspecialization/:id' element={
        <ProtectedRoute allowedRoles={['super-admin']}>
          <ManagerSpecialization/>
        </ProtectedRoute>
      } />

          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;


// commet for pulling

