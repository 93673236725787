import React, { useContext, useEffect, useState } from 'react'
import pdf from '../../../MazenFontendDocumentation.pdf'
import { useParams } from 'react-router-dom'
import Loading from '../Loading/Loading';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function PdfShow() {
  const {id} = useParams()
  const [book,setBook] = useState(pdf);
  const {user} = useContext(UserContext)
  const [loading,setLoading] = useState();
  const getBookById = async (book) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/book/view/${book}?userID=${user?._id}`,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log("onebok", res.data);
        setBook(res.data.book);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(()=>{
    getBookById(id)
  },[])
  return (
    loading ? <Loading/> :
    <div>
      <div>
        <Navbar/>
      </div>
      <div>
        <iframe
        src={book.bookPDF + '#toolbar=0'} // Disabling the toolbar
        width="100%"
        height="600px" // Set height as needed
        style={{ border: 'none' }} // No border
        allowFullScreen
        title="PDF Document"
      />
    </div>
    <div>
      <Footer/>
    </div>
    </div>
  )
}

export default PdfShow
