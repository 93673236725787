import React, { useEffect, useState } from 'react';
import './Catageries.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSessionErrorHandler } from '../../User/Managesession';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Loading from '../../User/Loading/Loading';


export default function AdminCategory() {

  const {token} = useContext(UserContext);
const handleSessionError = useSessionErrorHandler();

  const [popup, setPopup] = useState(false);
  const [loading , setLoading] = useState(false)
  const [activeDepartmentIndex, setActiveDepartmentIndex] = useState(null);
  const [delsection, setDelsection] = useState(false);
  const [openmodel, setopenmodel] = useState(false);
  const [departments, setDepartments] = useState([
   
  ]);

  useEffect(() => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_SERVER_URL}/section/getallsections`,{headers : {authorization : `Bearer ${token}`}})
      .then(response => {
        // console.log(response.data);
        setDepartments(response.data.data);
      })
      .catch(error => {
        // console.error(error);
        handleSessionError(error);
      });
      setLoading(false)
  }, []);

  const popupshow = (index,e) => {
    e.stopPropagation();
    setPopup(!popup);
    setActiveDepartmentIndex(activeDepartmentIndex !== index ? index : null);
  };

  const navigate = useNavigate();



  const deletepopup = (e) => {
    setPopup(false)
    e.stopPropagation();
    setDelsection(!delsection);
  };

  const addsomething = (e) => {
    setPopup(false)
    e.stopPropagation();
    setopenmodel(!openmodel);
  };

  const gotheaddcategory = () => {
    navigate('/addcatageries')
  }

const addeletefunction = ()=>{
    setDelsection(false);
    alert("add delete fucntion")
}

const addfunctionclick = ()=>{
    setopenmodel(false);
}

const gotosubcatageries = (id)=>{
    navigate(`/subcatageries/${id}`)
}

  return (
    loading ? <Loading/> :
    <div>
      <Navbar />

      {(delsection || openmodel) && <div className="overlay"></div>}

      {delsection && (
        <div>
          <div className="mainparentdiv">
            <div className="deletedmaindiv">
              <h1>سبب الحذف </h1>
              <div className='insidethemaindiv'>
                <textarea placeholder='مختلف عن موضوع القسم'></textarea>
              </div>
              <div className='deletediconandtextdiv' onClick={addeletefunction}>
              <Icon icon="ic:baseline-delete" width="1.2em" height="1.2em" />
                <h3>حذف</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      {openmodel && (
        <div>
          <div className="mainparentdiv">
            <div className="addmodelmaindiv">
              <h1>اسم القسم </h1>
              <input type="text" />
              <div className='addmodalbtndiv' onClick={addfunctionclick}>
                <h3>حفظ التعديل</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="maindivofadmin_department">
        <h1>الأقسام</h1>
        <div className="mainflexdivofadmin">
          {departments.map((department, index) => (
            <div key={department.title} className="maindepartmentdiv2" onClick={()=>gotosubcatageries(department._id)}>
              {/* <Icon onClick={(e) => popupshow(index,e)} icon="bi:three-dots-vertical" width="2em" height="2em" /> */}
              <h2>{department.title}</h2>
              {popup && activeDepartmentIndex === index && (
                <div className="smallpopupdiv">
                  <div onClick={(e)=>addsomething(e)}>تعديل</div>
                  <div onClick={(e)=>deletepopup(e)}>حذف</div>
                </div>
              )}
            </div>
          ))}

        </div>
      </div>

      <Footer />
    </div>
  );
}